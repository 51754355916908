const ProgressFlow = () => {
    
    const call = require('../Images/ProgressFlow/3d-illustration-icon-of-phone-call-with-circular-or-round-podium-png.webp');
    const checklist = require('../Images/ProgressFlow/checklist-5422281-4544489.webp');
    const contract = require('../Images/ProgressFlow/web-development-4453170-3718854.webp');

    return (
    
        <div className="container-fluid bg-dark text-white p-2" id="progress-flow">

        <div className="container text-center justify-content-center align-items-center  d-flex flex-column" id="progress-flow">
      
                <div className="text-center p-1 mt-5" id="progress-flow">

                    <div className="headertext2">
                        Working with Us is Easy as 1-2-3
                    </div>
                    <div className="lead mt-2 textpharagraph">
                        Every Big Thing Starts with a Small Step. Let's start with a call.
                    </div>

                    <div className="row justify-content-center position-relative" id="progress-flow-row">
                        <div className="col-md-3 text-white" id="col1">
                            <div className="container pt-5 pb-5">
                                <div className="text-center" id="progress-flow-img">
                                    <img src={call} alt="call" className="img-fluid" />
                                </div>
                                <h5 className="display-4 fw-bold pt-3 pb-2">
                                   First Meeting
                                </h5>
                                <p className="lead">
                                    We will start by discussing your project requirements, goals, and expectations.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-3 text-white" id="col2">
                            <div className="container pt-5 pb-5">
                                <div className="text-center" id="progress-flow-img">
                                    <img src={checklist} alt="checklist" className="img-fluid" />
                                </div>
                                <h5 className="display-4 fw-bold pt-3 pb-2">
                                    Analyze & Plan
                                </h5>
                                <p className="lead">
                                    Once we have a clear understanding of your requirements, we will create a detailed project plan.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-3 text-white" id="col3">
                            <div className="container pt-5 pb-5">
                                <div className="text-center" id="progress-flow-img">
                                    <img src={contract} alt="contract" className="img-fluid" />
                                </div>
                                <h5 className="display-4 fw-bold pt-3 pb-2">
                                    Develop & Deliver
                                </h5>
                                <p className="lead">
                                    Our team of experts will work diligently to develop your project according to the plan. 
                                </p>
                            </div>
                        </div>
                    </div>


                </div>
        </div>
    </div>
    );
    }

export default ProgressFlow;