import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../../Styles/Contact.css';

const ContactsForm = () => {
    const [notification, setNotification] = useState<string | null>(null);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log("Form Submitted");

        const serviceID = 'service_s06hkax';
        const templateID = 'template_axoha3i';
        const userID = 'IQJSSPJahRTK33r_h'; // Ensure this is your correct Public Key

        emailjs.sendForm(serviceID, templateID, e.target as HTMLFormElement, userID)
            .then((response) => {
                console.log('Email sent:', response.text);
                setNotification('We have received your message and will get back to you shortly.');
                // Optionally, you can reset the form here:
                // e.target.reset();
            }, (error) => {
                console.error('Email error:', error.text);
                setNotification('There was an error submitting your form. Please try again later.');
            });
    }

    return (
        <div>
            {notification && (
                <div className="alert alert-success" role="alert">
                    {notification}
                </div>
            )}
            
            <form onSubmit={onSubmit} id="contact-form" className="container text-start p-3 w-75">

                <div className="display-4 fw-bold pt-3 pb-3" id="text1">
                    We would love to hear from you!
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="FirstName" className="form-label">First Name <span className="text-danger">*</span></label> <br />
                        <input type="text" name="FirstName" placeholder="First Name" className='input-field' required /> <br />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="LastName" className="form-label">Last Name <span className="text-danger">*</span></label> <br />
                        <input type="text" name="LastName" placeholder="Last Name" className='input-field' required /> <br />
                    </div>
                </div>

                <label htmlFor="Email" className="form-label">Your Email <span className="text-danger">*</span></label> <br />
                <input type="email" name="Email" placeholder="Email" className='input-field' required /> <br />

                <label htmlFor="ProjectType" className="form-label"> What type of collaboration are you looking for? <span className="text-danger">*</span></label> <br />
                {/** Add Selection as Checkbox and text box for any other project type */}
                <div className="form-check text-start mb-3 mt-3 w-100" id='ProjectType'>
                    <div className="form-check pb-2">
                        <input className="form-check-input" type="checkbox" name="ProjectType" value="Software Development" id="softwareDevelopment" />
                        <label className="form-check-label" htmlFor="softwareDevelopment"> I'm looking for Software Development</label>
                    </div>
                    <div className="form-check pb-2">
                        <input className="form-check-input" type="checkbox" name="ProjectType" value="Ai and Machine Learning" id="aiMachineLearning"  />
                        <label className="form-check-label" htmlFor="aiMachineLearning"> I'm looking for AI and Machine Learning Project</label>
                    </div>
                    <div className="form-check pb-2">
                        <input className="form-check-input" type="checkbox" name="ProjectType" value="Looking for increase team" id="increaseTeam"  />
                        <label className="form-check-label" htmlFor="increaseTeam"> I'm looking to increase my team</label>
                    </div>
                    <div className="form-check pb-2">
                        <input className="form-check-input" type="checkbox" name="ProjectType" value="Looking to Research and Development" id="researchDevelopment"  />
                        <label className="form-check-label" htmlFor="researchDevelopment"> I'm looking to Research and Development</label>
                    </div>
                    <div className="form-check pb-2">
                        <input className="form-check-input" type="checkbox" name="ProjectType" value="Looking for Consultation" id="consultation"  />
                        <label className="form-check-label" htmlFor="consultation"> I'm looking for Consultation for my project</label>
                    </div>
                    <div className="form-check pb-2">
                        <input className="form-check-input" type="checkbox" name="ProjectType" value="Looking for Partnership" id="partnership"  />
                        <label className="form-check-label" htmlFor="partnership"> I'm looking for Partnership</label>
                    </div>
                    <div className="form-check pb-2">
                        <input className="form-check-input" type="checkbox" name="ProjectType" value="Looking for Investment" id="investment"  />
                        <label className="form-check-label" htmlFor="investment"> I'm looking for Investment</label>
                    </div>
                    <div className="form-check pb-2">
                        <input className="form-check-input" type="checkbox" name="ProjectType" value="Looking for Other" id="other"  />
                        <label className="form-check-label" htmlFor="other"> I'm looking for Other</label>
                    </div>
                </div>

                <label htmlFor="ProjectDetails" className="form-label"> Tell us about your project <span className="text-danger"></span></label> <br />
                <textarea name="ProjectDetails" placeholder="Project Details" className='input-field' /> <br />

                <label htmlFor="weburl" className="form-label">Website of Business</label> <br />
                <input type="url" name="weburl" placeholder="https://www.example.com" className='input-field' /> <br />

                <label htmlFor="contactnumber" className="form-label">Contact Number <span className="text-danger">*</span></label> <br />
                <input type="tel" name="contactnumber" placeholder="Contact Number" className='input-field' required /> <br />

                {/* <label htmlFor="Country" className="form-label">Country <span className="text-danger">*</span></label> <br />
                <input type="text" name="Country" placeholder="Country" required className='input-field' /> <br /> */}

                <button type="submit" className="btn btn-primary mt-3 mb-3 w-100">
                    Submit
                </button>

                {/** Notification */}
                {notification && (
                <div className="alert alert-success" role="alert">
                    {notification}
                </div>
                 )}

            </form>
        </div>
    );
}

export default ContactsForm;
