const PageHeaders = (prop : {title: any, subtitle: any}) => {
    return (
        <div className="container-fluid text-start bg-dark"
        id="Page-Header-Component">
            <div className="container">
            <h1 className="page-title display-4 fw-bold pt-3 pb-2">{prop.title}</h1>
            <h2 className="page-subtitle">{prop.subtitle}</h2>
            </div>
        
        </div>
    );
}

export default PageHeaders;