import { Routes, Route, Navigate,BrowserRouter } from 'react-router-dom' 

import Home from '../Pages/Home'
import Products from '../Pages/Products'
import Services from '../Pages/Services'
import Clients from '../Pages/Clients'
import Casestudies from '../Pages/Casestudies'
import Contacts from '../Pages/Contacts'
import PrivacyPolicy from '../Components/ExtraComponents/PrivacyPolicy'
import TermsAndConditions from '../Components/ExtraComponents/TermsAndConditions'
import Partnership from '../Pages/Partnership'
import Growth from '../Pages/Growth'
import Careers from '../Pages/Careers'
import Team from '../Pages/Team'
import Blog from '../Pages/Blog'


const AppRouter = () => {
    return (
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/services" element={<Services />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/casestudies" element={<Casestudies />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/Partnership" element={<Partnership/>} />
            <Route path="/careers" element={<Careers/>} />
            <Route path="/team" element={<Team/>} />
            <Route path="/Growth" element={<Growth/>} />
            <Route path="/Blog" element={<Blog/>} />
            <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
        </BrowserRouter>
    )
    }

export default AppRouter;