import React from 'react';

const ServiceHeader = () => {
    return (
        <div className="container-fluid bg-dark" id="service-header">
            <div className="container pt-5 pb-5 pr-5">
                <div className="row">
                    {/* Column 1 */}
                    <div className="col-md-9 text-start">
                        <h1 id="text1" className="display-4 fw-bold pt-3 pb-2">
                            Great Technologies <br/> For<br/> Great Solutions
                        </h1>
                    </div>
                        
                    {/* Column 2 */}
                    <div className="col-md-5 text-start">

                    </div>
                </div>       
            </div>
        </div>
    );
}

export default ServiceHeader;
