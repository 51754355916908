import PageHeaders from "../Components/ExtraComponents/PageHeaders"
import GYMLY from "../Components/Products/GYMLY";
import OpenSource from "../Components/Products/OpenSource";
import '../Styles/Products.css';
import ReactGA from 'react-ga4';


const Products = () => {

    document.title = "Akwid Labs | AI Driven Digital Products for Business";
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
   
    return (
    
    <div>
                
            <PageHeaders
                title={
                    <>
                    Digital Products <br /> 
                    For <br />
                    Level-up Your Business
                    </>
                }
                subtitle={<></>}
            />
            
            {/** make this center */}
            <div className="container justify-content-center mt-5 mb-5 p-3">
                <GYMLY />
            </div>

            <div className="container justify-content-center mt-5 mb-5 p-3">
                <OpenSource />
            </div>


    </div>
    );
    }

export default Products;