const GYMLY = () => {

    const gymlylogo = require('../../Images/Products/gymly.png');

    return (
        <div className="container text-start bg-dark" id="GYMLY">

                <h1 className="display-4 fw-bold pt-3 pb-2 text-white text-center" id="text1">
                    GYMLY Global </h1>

                <div className="row p-2 justify-content-center">

                    <div className="col-md-4 p-5">
                        <img src={gymlylogo} alt="Logo of GYMLY Global Pvt Ltd"
                        className="img-fluid" id="logo" />
                    </div>

                    <div className="col-md-7 text-white p-3"
                    id="description">
                        <p>
                            GYMLY is a fitness and wellness platform that connects users with fitness trainers and wellness experts. 
                            The platform offers a range of services including personal training, group classes, and wellness coaching. 
                            GYMLY also provides users with access to a library of workout videos and wellness content. 
                            The platform is available on both web and mobile, making it easy for users to access fitness and wellness services anytime, anywhere.
                        </p>

                        <a href="https://www.gymly.in/" target="_blank" rel="noreferrer" className="text-white">
                            Try GYMLY Global &#62;
                        </a>
                    </div>
                </div>

        </div>
    );
    }
export default GYMLY;