import React, { ReactNode, useEffect, useState } from 'react';
import Loading from '../../commen/Loading/Loading'; // Corrected the import path for 'common'
import axios from 'axios';

const Mediumblogs = () => {
    const [blogs, setBlogs] = useState<{
        content: string | TrustedHTML;
        thumbnail: string | undefined;
        title: ReactNode;
        link: string | undefined;
        guid: string;
    }[]>([]);
    const [isLoading, setIsLoading] = useState(true); // Added loading state

    const getMediumBlogs = async () => {
        try {
            const response = await axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@masterlwa');
            setBlogs(response.data.items);
        } catch (error) {
            console.error("Error fetching Medium blogs:", error);
        } finally {
            setIsLoading(false); // Set loading to false once data is fetched
        }
    };

    useEffect(() => {
        getMediumBlogs();
    }, []);

    const extractFirstImageUrl = (description: string): string | null => {
        const match = description.match(/<img[^>]+src="([^">]+)"/);
        return match ? match[1] : null;
    };

    return (
        <div className="container" id="Mediumblogs">
            <div className="headertext1 text-start mt-5 mb-5">
                Our Latest Blogs
            </div>

            {isLoading ? (
                <Loading /> // Show loader while loading
            ) : (
                <div className="row">
                    {blogs.map((post) => {
                        const imageUrl = post.thumbnail || extractFirstImageUrl(post.content as string);
                        
                        return (
                            <div key={post.guid} className="col-md-4">
                                <div className="card mb-4">
                                    {imageUrl && (
                                        <img src={imageUrl} className="card-img-top" alt={post.title as string} />
                                    )}
                                    <div className="card-body">
                                        <h5 className="card-title">{post.title}</h5>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: ((post.content as string)
                                            .replace(/<img[^>]*>/g, '') // Remove images
                                            .replace(/<a[^>]*>([^<]+)<\/a>/g, '$1') // Remove links
                                            .substring(0, 100)) +
                                            ((post.content as string).length > 100 ? '...' : '')
                                        }} />
                                        <a href={post.link} target="_blank" rel="noopener noreferrer" className="btn btn-primary" id='readmore'>
                                            Read more
                                        </a>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Mediumblogs;
