import React from "react";
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga4';


const Header = () => {

    ReactGA.initialize('G-1H81WDN33T');

    return (
        <div>
            

            <div id="home-component1" className="text-center">
                <div className="container" id="home-component1-text1">
                   Build Technologies to Next Generation
                </div>
                <div className="container" id="home-component1-text2">
                    Combination of Engineers and Creative Minds specialized in Software Engineering and Consulting, Cloud Technologies, Artificial Intelligence and Web Technologies.
                </div>
                <div className="text-center">
                        <Button
                            onClick={() => window.open("/contacts", "_self")}
                        className="contact-button btn btn-outline-primary pt-2 pb-2 pl-3 pr-3 mt-5"
                        >
                            Let's Talk
                         </Button>

                 </div>
                 
            </div>



   

        </div>
    );
    }

export default Header;