import React from 'react';

const UnderDevelopment = () => {
    return (
        <div className="under-development text-center d-flex justify-content-center align-items-center mt-5 mb-5 pt-5 pb-5 h-100">
            <div className="text-center">
                <h1 className="display-4 fw-bold pt-3 pb-2 text-dark" id="text1">
                    Under Development
                </h1>
                <p className="text-dark">This page is currently under development. Please check back later.</p>
                <div className="container mt-5 p-3 d-flex justify-content-center">
                    <div className="loader"></div>
                </div>
            </div>
        </div>
    );
}

export default UnderDevelopment;
