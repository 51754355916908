import React from 'react';

const TrustedBy = () => {
    const FitnessLeafAI = require('../Images/Clients/Fitness-Leaf-AI-logo-Transparent-bg-400x100-1.png');
    const otagoPolytechnic = require('../Images/Clients/otago-polytechnic-horizontal-blue.png');
    const orpheus = require('../Images/Clients/orpheus-digital.png');
    const hagosMarketing = require('../Images/Clients/hagos_marketing_logo-_blue_transparent_background__1_.png');

    return (
        <div className="container mt-0 mb-3 p-1" id="clients-brands">
            <div className="text-center mt-4 mb-4 headertext1" id="brands-logo-text"> 
                Trusted by
            </div>
    
            <div className="row align-items-center justify-content-center tech-stack-animation p-5">
                <div className="col-6 col-sm-4 col-md-3 d-flex justify-content-center mb-4">
                    <img 
                        src={FitnessLeafAI} 
                        alt="FitnessLeafAI" 
                        className="img-fluid" 
                    />
                </div>
    
                <div className="col-6 col-sm-4 col-md-3 d-flex justify-content-center mb-4">
                    <img 
                        src={otagoPolytechnic} 
                        alt="Otago Polytechnic" 
                        className="img-fluid" 
                    />
                </div>
    
                <div className="col-6 col-sm-4 col-md-3 d-flex justify-content-center mb-4">
                    <img 
                        src={hagosMarketing} 
                        alt="Hagos Marketing" 
                        className="img-fluid" 
                    />
                </div>
    
                <div className="col-6 col-sm-4 col-md-3 d-flex justify-content-center mb-4">
                    <img 
                        src={orpheus} 
                        alt="Orpheus Digital" 
                        className="img-fluid" 
                    />
                </div>
            </div>
    
            <div className="text-center m-2 pt-4" id="paragraph">
                <b>+ Many more</b>
            </div>
        </div>
    );
}

export default TrustedBy;
