import PageHeaders from "../Components/ExtraComponents/PageHeaders";
import UnderDevelopment from "../Components/ExtraComponents/UnderDevelopment";
import ReactGA from 'react-ga4';

const Team = () => {

    document.title = "Akwid Labs | Team";

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

    return (
        <div>
            <PageHeaders
                title={
                    <>
                        Our Team <br />
                        of <br />
                        Problem Solvers
                    </>
                }
                subtitle={<></>}
            />
            <UnderDevelopment />
        </div>
    );
    }

export default Team;