import AppRouter from "./Approuter";
import Header from "./Header";
import Footer from "./Footer";

const Structure = () => {
    return (
      <>
        <Header />
        <AppRouter />
        <Footer />
      </>
    )
    }

export default Structure;