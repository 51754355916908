import * as Icon from 'react-bootstrap-icons';

const Service = () => { 
    return (
        <div id="home-services" className="container-fluid">
            <div className="container text-center mx-auto justify-content-center" id="component1">
                <h1 className="text-center text-white" id="text1">
                    Let's Build Your Next Big Thing
                </h1>
                <div className="container row mx-auto justify-content-center mt-3" id="row1">
                    <div className="col-md-4 text-center d-flex flex-column align-items-center div1" id="col1">
                        <h2>Engineering</h2>
                        <div className="d-flex justify-content-center m-3">
                            <Icon.CodeSlash className='icon m-3' />
                        </div>
                        <p>
                            Unlock your business's full potential with our products and tailored software solutions. We're here to support you through all stages of the Software Development Life Cycle, crafting the most suitable products to drive your success. Discover how our expertise can elevate your business today.
                        </p>
                        {/* Button placed near the bottom of the card */}
                        <button className="btn btn1 mt-auto m-2">
                            Learn More <Icon.ArrowRight />
                        </button>
                    </div>
                    <div className="col-md-4 text-center d-flex flex-column align-items-center div1"  id="col2">
                        <h2>Growth</h2>
                        <div className="d-flex justify-content-center m-3">
                            <Icon.RocketTakeoff className='icon m-3' />
                        </div>
                        <p >
                            Fuel your business's growth with our cutting-edge digital solutions. Our team of experts is dedicated to helping you achieve your business goals through innovative technology. From web development to AI, we have the tools and knowledge to drive your business forward. Discover how we can help you grow today.
                        </p>
                        {/* Button placed near the bottom of the card */}
                        <button className="btn btn1 mt-auto m-2">
                            Learn More <Icon.ArrowRight />
                        </button>
                    </div>
                    <div className="col-md-4 text-center d-flex flex-column align-items-center div1"  id="col3">
                        <h2>Consulting</h2>
                        <div className="d-flex justify-content-center m-3">
                            <Icon.People className='icon m-3' />
                        </div>
                        <p>
                            Navigate the complexities of technology with confidence through our expert consulting services. Whether you're seeking guidance on digital transformation or strategic planning, our team is here to empower your business. Discover how our tailored consulting solutions can drive innovation and growth for your organization.
                        </p>
                        {/* Button placed near the bottom of the card */}
                        <button className="btn btn1 mt-auto m-2">
                            Learn More <Icon.ArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
