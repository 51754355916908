import React, { useEffect } from 'react';

declare global {
    interface Window {
        Rooster: any;
    }
}

const RoosterWidget = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://roostercdn.s3-ap-southeast-1.amazonaws.com/rooster.min.js';
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => {
            const rooster = new window.Rooster('#rooster', 151778);
            rooster.setup();
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div id="rooster"></div>
    );
};

export default RoosterWidget;
