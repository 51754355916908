const TechStackLogo = () => {
     
    const react = require('../Images/Logos-Stacks/React.png');
    const angular = require('../Images/Logos-Stacks/angular.png');
    const python = require('../Images/Logos-Stacks/Python-logo.png');
    const nodejs = require('../Images/Logos-Stacks/2560px-Node.js_logo.svg.png');
    const mongodb = require('../Images/Logos-Stacks/mongo.png');
    // const azure = require('../Images/Logos-Stacks/Microsoft_Azure.svg.png');
    // const aws = require('../Images/Logos-Stacks/2022_Zoom-AWS_Lockup_RGB-1-e1672857797889-1024x760.webp');
    const spring = require('../Images/Logos-Stacks/spring-boot-logo.png');
    const openai = require('../Images/Logos-Stacks/open-ai-logo.png');
    const googleanlysis = require('../Images/Logos-Stacks/Logo_Google_Analytics.svg.png');
    const figma = require('../Images/Logos-Stacks/46a76c802176eb17b04e12108de7e7e0f3736dc6-1024x1024.webp');
    const typescript = require('../Images/Logos-Stacks/Typescript_logo.png');
    const Stripe = require('../Images/Logos-Stacks/StripeLogo.jpeg');
    const electron = require('../Images/Logos-Stacks/electon.png');
    const xcode = require('../Images/Logos-Stacks/Xcode_14_icon.png');
    const wordpress = require('../Images/Logos-Stacks/WordPress-logotype-alternative.png');

    return (
      <div className="container mt- mb-3 p-1 text-center justify-content-center align-items-center  d-flex flex-column" id="tech-stack-logo">

        <div className="text-center mt-4 mb-5" id="tech-stack-logo-text"> 
        We are experts of</div>

        <div className="row align-items-center justify-content-center tech-stack-animation">

            <div className="col" >
                <img src={react} alt="react"className="img-fluid" />
            </div>

            <div className="col">
                <img src={angular} alt="angular" className="img-fluid" />
            </div>
        
            <div className="col">
                <img src={python} alt="python" className="img-fluid" />
            </div>

            <div className="col">
                <img src={nodejs} alt="nodejs" className="img-fluid" />
            </div>

            <div className="col">
                <img src={electron} alt="electron" className="img-fluid" />
            </div>

            <div className="col">
                <img src={spring} alt="spring" className="img-fluid" />
            </div>

            <div className="col">
                <img src={mongodb} alt="mongodb" className="img-fluid" />
            </div>

            <div className="col">
                <img src={openai} alt="openai" className="img-fluid" />
            </div>

            <div className="col">
                <img src={googleanlysis} alt="googleanlysis" className="img-fluid" />
            </div>

            <div className="col">
                <img src={figma} alt="figma" className="img-fluid" />
            </div>

            <div className="col">
                <img src={typescript} alt="typescript" className="img-fluid" />
            </div>

            <div className="col">
                <img src={xcode} alt="xcode" className="img-fluid" />
            </div>

            <div className="col">
                <img src={Stripe} alt="Stripe" className="img-fluid" />
            </div>

            <div className="col">
                <img src={wordpress} alt="wordpress" className="img-fluid" />
            </div>

            <div className="col">
            </div>
        </div>

        <div className="text-center m-2 pt-5" id="tech-stack-pharagraph" style={{color: "#6c757d"}}>
            <b>+ Many more</b>
        </div>

      </div>
    );
  }
  
  
  export default TechStackLogo;