import * as Icon from 'react-bootstrap-icons';

const ServiceCatogories = () => {
    return (
        <div className="container justify-content-center text-center" 
        id="service-catogories">
            <h2 id="text1">
                How we empower your business?
            </h2>

            {/* Added justify-content-center to center the content horizontally */}
            <div className="row equal-height-row">
                 {/* Added equal-height-row class */}

                {/* row 01 */}
                <div className="col-md-4">
                    <div className="service-catogory">
                        <h3>App Development</h3>
                        <div className="d-flex justify-content-center m-3">
                            <Icon.CodeSlash
                            className='icon' />
                        </div>
                        <p className="text-start">
                        Leverage our expertise to develop custom web and mobile applications that drive innovation, enhance user experiences, and deliver tangible results for your business.
                        </p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="service-catogory">
                        <h3>
                            Digital Marketing
                        </h3>
                        <div className="d-flex justify-content-center m-3">
                            <Icon.Megaphone
                            className='icon' />
                        </div>
                        <p className="text-start">
                        Accelerate your growth with data-driven digital marketing strategies that drive engagement, increase conversions, and build brand loyalty across all channels.
                        </p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="service-catogory">
                        <h3> Product Design</h3>
                        <div className="d-flex justify-content-center m-3">
                            <Icon.Display
                            className='icon' />
                        </div>
                        <p className="text-start">
                        Elevate your digital presence with stunning UI/UX designs crafted to delight users, enhance usability, and leave a lasting impression at every interaction.
                        </p>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="service-catogory">
                        <h3>
                            Team Augmentation
                        </h3>
                        <div className="d-flex justify-content-center m-3">
                            <Icon.People
                            className='icon' />
                        </div>
                        <p className="text-start">
                            Do you need to scale your team quickly? We provide top-tier tech talent to help you meet your project goals and deadlines. We've one to many engineers, scientists, project managers and consultants.
                        </p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="service-catogory">
                        <h3>
                            Cloud Computing
                        </h3>
                        <div className="d-flex justify-content-center m-3">
                            <Icon.Cloud
                            className='icon' />
                        </div>
                        <p className="text-start">
                        Unlock the power of the cloud to streamline operations, enhance flexibility, and scale your infrastructure securely, ensuring optimal performance and cost-effectiveness.
                        </p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="service-catogory">
                        <h3>
                          Data Analytics
                        </h3>
                        <div className="d-flex justify-content-center m-3">
                            <Icon.BarChart
                            className='icon' />
                        </div>
                        <p className="text-start">
                        Harness the potential of data analytics to drive informed decision-making, optimize processes, and unlock new opportunities for growth and innovation within your organization.
                        </p>
                    </div>
                </div>
            </div>

            {/* And Many  */}
            <div id="text2" className="text-center mt-2 mb-1 fw-bold">
                And many more! Discover how our expertise can empower your business today.
            </div>
  
        </div>
    );
}

export default ServiceCatogories;
