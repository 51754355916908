const OpenSource = () => {
    return (
        <div className="container text-start bg-dark" id="OpenSource">

                <div className="row p-3 justify-content-center">

                    <div className="col-md-6 p-4">
                        <div className="headertext2" id="text1">
                            We <br />
                            Love <br />
                            Open Source 
                        </div>
                    </div>

                    <div className="col-md-6 text-white p-5"
                    id="description">
                        <p>
                           We love to build and contribute to open source projects. We believe in the power of open source software and the community that supports it.
                           So, we build Open Source products like API wrappers, libraries, and tools that help developers to build better software. Also, for Businesses. 
                           We also contribute to open source projects and support the community by sharing our knowledge and expertise. Let's build something great together.
                        </p>

                        <a href="https://github.com/Akwid-Labs" target="_blank" rel="noreferrer" className="text-white">
                            Find us on GitHub &#62;
                        </a>
                    </div>
                </div>

        </div>
    );
    }

export default OpenSource