import Careersintro from '../Components/Careers/Careersintro';
import RoosterComponent from '../Components/Careers/RoosterComponent';
import PageHeaders from '../Components/ExtraComponents/PageHeaders';
import '../Styles/careers.css';
import ReactGA from 'react-ga4';

const careers = () => {

    document.title = "Akwid Labs | Careers";

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

    return (
        <div>
            <PageHeaders
                title={
                    <>
                        Careers
                        <br />
                        at 
                        <br />
                        Akwid Labs
                    </>
                }
                subtitle={<>
                    Join us in our misson to empower businesses with AI-driven solutions.
                </>}
            />

            <Careersintro/>
            
            <div className="container mt-5 mb-5 pl-3 pr-3">
                <div className="text-center headertext1 mb-5">
                        Available Positions
                </div>
                <RoosterComponent/>
            </div>
        </div>
    );
    }

export default careers