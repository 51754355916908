import PageHeaders from "../Components/ExtraComponents/PageHeaders";
import Mediumblogs from "../Components/Blog/Mediumblogs";
import '../Styles/blogs.css';
import ReactGA from 'react-ga4';

const Blog = () => {

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

    document.title = "Akwid Labs | Blog";

    return (
        <div>
            <PageHeaders
                title={
                    <>
                        Blog <br />
                        
                    </>
                }
                subtitle={<>
                    We share our thoughts and insights on the latest trends in technology and how we can empower businesses with our expertise.
                </>}
            />

            <div className="mt-5 mb-5">
                <Mediumblogs />
            </div>
        </div>


    );
    }

export default Blog;