const Infotab = () => {

    const img1 = require('../../Images/infotab-img1.png');

    return (
    <div id="home-infotab" className="container-fluid bg-white pt-5 pb-4">

        <div className="container">

            {/* row 1 */}
            <div className="row justify-content-center align-items-center ml-5 mr-5">

                    {/* Column 1 */}
                    <div className="col-md-6 m-3" id="col2">
                            <div className="text-start" id="infotab-text1">
                                Technology Partner of <br/>Your Success Story 
                            </div>

                            <p className="text-start mt-6" id="infotab-text2">
                            As your committed technology partner, we're deeply invested in helping you succeed. With our vast expertise in software engineering, cloud solutions, AI, and various technologies, we simplify navigating the digital landscape for you. From brainstorming ideas to delivering the final product, we provide personalized solutions, products, and consulting services designed to fuel the growth of your business.                        
                            </p>

                            <button className="btn btn-primary mt-3" id="infotab-button">
                                Let's Get Started
                            </button>
                    </div>

                    {/* Column 2 */}
                    <div className="col-md-5" id="col1">
                            <div className="mt-5">
                            <img src={img1} className="img-fluid text-center pt-3 pb-1 pl-1"
                            alt="infotab" id="infotab-img"/>
                            </div>
                    </div>
                    
            </div>

        </div>
            
    </div>
    );
    }

export default Infotab;