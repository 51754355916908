import '../../Styles/privacypolicy.css';

const PrivacyPolicy = () => {
    return (
      <div id="privacy-policy" className="container mt-5">
        <h1 id="policy-title" className="text-center">Privacy Policy</h1>
        <p id="effective-date" className="text-center"><strong>Effective Date:</strong> July 10, 2024 </p>
  
        <section id="introduction" className="my-4">
          <h2 id="introduction-title">Introduction</h2>
          <p id="introduction-content">
            Welcome to Akwid Labs! Your privacy is critically important to us. At Akwid Labs, we have a few fundamental principles:
          </p>
          <ul id="principles-list">
            <li id="principle-1">We are thoughtful about the personal information we ask you to provide and the personal information that we collect about you through the operation of our services.</li>
            <li id="principle-2">We store personal information for only as long as we have a reason to keep it.</li>
            <li id="principle-3">We aim for full transparency on how we gather, use, and share your personal information.</li>
          </ul>
        </section>
  
        <section id="what-this-policy-discloses" className="my-4">
          <h2 id="what-this-policy-discloses-title">What This Privacy Policy Discloses</h2>
          <p id="what-this-policy-discloses-content">
            This privacy policy discloses the privacy practices for Akwid Labs. This privacy policy applies solely to information collected by this website. It will notify you of the following:
          </p>
          <ul id="disclosures-list">
            <li id="disclosure-1">What personally identifiable information is collected from you through the website, how it is used, and with whom it may be shared.</li>
            <li id="disclosure-2">What choices are available to you regarding the use of your data.</li>
            <li id="disclosure-3">The security procedures in place to protect the misuse of your information.</li>
            <li id="disclosure-4">How you can correct any inaccuracies in the information.</li>
          </ul>
        </section>
  
        <section id="how-we-collect-and-manage" className="my-4">
          <h2 id="how-we-collect-and-manage-title">How We Collect and Manage Information</h2>
          <p id="how-we-collect-and-manage-content">
            We collect and manage information in the following ways:
          </p>
          <ul id="collection-methods-list">
            <li id="collection-method-1"><strong>Direct Collection:</strong> Information you provide to us directly when you contact us, subscribe to our newsletter, or use our services.</li>
            <li id="collection-method-2"><strong>Indirect Collection:</strong> Information we collect automatically, such as IP addresses, browser type, and access times.</li>
            <li id="collection-method-3"><strong>Third-Party Collection:</strong> Information we receive from third-party services we use to support our business operations.</li>
          </ul>
        </section>
  
        <section id="information-we-collect" className="my-4">
          <h2 id="information-we-collect-title">Information We Collect</h2>
          <p id="information-we-collect-content">We collect information in the following ways:</p>
          <ul id="information-types-list">
            <li id="information-type-1">
              <strong>Information you provide to us directly:</strong> We may collect personal information such as your name, email address, phone number, and company details when you contact us, subscribe to our newsletter, or use our services.
            </li>
            <li id="information-type-2">
              <strong>Information from third parties:</strong> We may receive information about you from third-party services we use to support our business operations.
            </li>
            <li id="information-type-3">
              <strong>Information we collect automatically:</strong> We collect certain information automatically, including IP addresses, browser type, and access times.
            </li>
          </ul>
        </section>
  
        <section id="how-we-use-information" className="my-4">
          <h2 id="how-we-use-information-title">How We Use Information</h2>
          <ul id="use-cases-list">
            <li id="use-case-1">To provide, operate, and maintain our services.</li>
            <li id="use-case-2">To improve, personalize, and expand our services.</li>
            <li id="use-case-3">To understand and analyze how you use our services.</li>
            <li id="use-case-4">To develop new products, services, features, and functionalities.</li>
          </ul>
        </section>
  
        <section id="gdpr" className="my-4">
          <h2 id="gdpr-title">GDPR</h2>
          <p id="gdpr-content">
            If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Akwid Labs aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal data.
          </p>
          <ul id="gdpr-rights-list">
            <li id="gdpr-right-1">The right to access – You have the right to request copies of your personal data.</li>
            <li id="gdpr-right-2">The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
            <li id="gdpr-right-3">The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
            <li id="gdpr-right-4">The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
            <li id="gdpr-right-5">The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
            <li id="gdpr-right-6">The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
          </ul>
          <p id="gdpr-contact">
            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our provided contact information.
          </p>
        </section>
  
        <section id="information-sharing" className="my-4">
          <h2 id="information-sharing-title">Information Sharing</h2>
          <p id="information-sharing-content">We do not share, sell, or disclose your personal information except in the following circumstances:</p>
          <ul id="sharing-cases-list">
            <li id="sharing-case-1">To comply with laws or to respond to lawful requests and legal processes.</li>
            <li id="sharing-case-2">To protect the rights and property of Akwid Labs, our agents, customers, and others, including to enforce our agreements, policies, and terms of use.</li>
            <li id="sharing-case-3">In an emergency, including to protect the personal safety of any person.</li>
            <li id="sharing-case-4">In connection with a sale, merger, or acquisition of all or a portion of our business.</li>
          </ul>
        </section>
  
        <section id="children-information" className="my-4">
          <h2 id="children-information-title">Children’s Information</h2>
          <p id="children-information-content">
            Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
          </p>
          <p id="children-information-content-2">
            Akwid Labs does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
          </p>
        </section>
  
        <section id="security" className="my-4">
          <h2 id="security-title">Security</h2>
          <p id="security-content">We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.</p>
        </section>
  
        <section id="changes-to-policy" className="my-4">
          <h2 id="changes-to-policy-title">Changes to This Privacy Policy</h2>
          <p id="changes-to-policy-content">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        </section>
  
        <section id="contact-us" className="my-4">
          <h2 id="contact-us-title">Contact Us</h2>
          <p id="contact-us-content">If you have any questions about this Privacy Policy, please contact us at:</p>

            <ul id="contact-information-list">
                <li id="contact-info-1"><strong>Email:</strong>
                    <a href="mailto: hello@akwidlabs.com"> hello@akwidlabs.com</a>
                </li>
                <li id="contact-info-2"><strong>Phone:</strong>
                    <a href="tel: +94769114371"> +94769114371</a>
                </li>
            </ul>
        </section>
      </div>
    );
  };
  
  export default PrivacyPolicy;