import Header from "../Components/Home/Header";
import Service from "../Components/Home/Services";
import Infotab from "../Components/Home/Infotab";
import Locations from "../Components/Home/Locations";
import '../Styles/Home.css';
import TechStackLogo from "../commen/TechStackLogo";
import TruestedBy from "../commen/TruestedBy";
import CallShedule from "../commen/CallShedule";
import ReactGA from 'react-ga4';


// import Loading from "../commen/Loading/Loading";

const Home = () => {

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

    return (
        <div>
            <div className="">
                <Header />
            </div>
            <div className="mb-5">
                <Service />
            </div>
            <div className="mt-5 mb-5">
                <Infotab />
            </div>
            <div className="mt-5 mb-5">
                <TechStackLogo />
            </div>
            <div className="mt-5 mb-5">
                <Locations />
            </div>
            <div className="mt-5 mb-5">
                <TruestedBy />
            </div>

                <CallShedule title="Let's Talk About Your Project" />

        </div>
    );
}

export default Home;
