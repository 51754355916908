
const CallShedule = (prop: { title: string }) => {

    return (
        <div className="container-fluid text-center mt-4 bg-dark"
        id="Shedule-Call-Component">
            
            <div className="container p-5">
                <div className="text-center">
                    
                    <div id="Shedule-Call-Component-Title">
                        {prop.title}
                    </div>
                    
                    <div className="d-flex justify-content-center mt-3 mb-3">
                        <button
                            onClick={() => window.open("/contacts", "_self")}
                            className="btn btn-outline pt-3 pb-3 pl-5 pr-5 contact-button m-1"
                        > 
                            SCHEDULE A CALL
                        </button>
                    </div>

                </div>
            </div>

        </div>
    );
    }

export default CallShedule;