const ProductEngineering = () => {
    return (
        <div className="container text-center mt-2 mb-3" id="product-engineering">

            {/* row 1 */}
            <div className="text-center p-3 mt-4 mb-5" id="bigHeader">
                <div id="part1">
                    Engineering Ai-Driven Products
                </div>

                <div id="part2">
                    No Matter
                </div>

                <div id="part3">
                    if You're a Startup or an Enterprise
                </div>
            </div>


            {/* row 2 */}
            <div className="row justify-content-center">

                <div className="col-md-5 bg-dark text-white" id="col3">
                    <div className="container pt-5 pb-5">
                        <h1 className="display-4 fw-bold pt-3 pb-2">Product Engineering</h1>
                        <p className="lead">
                            Akwid Labs is your trusted partner in product engineering. We specialize in developing cutting-edge software products that drive innovation, enhance user experience, and deliver tangible business results. Our team of experts works closely with you to understand your vision, goals, and requirements, ensuring that your product not only meets but exceeds expectations.
                        </p>
                    </div>
                </div>
                {/* Column 2 - service 2 */}
                <div className="col-md-5 text-white" id="col4">
                    <div className="container pt-5 pb-5">
                        <h1 className="display-4 fw-bold pt-3 pb-2"> Consulting </h1>
                        <p className="lead">
                            At Akwid Labs, we offer expert consulting services to help you navigate the ever-changing landscape of technology. Our team of seasoned professionals provides strategic guidance, technical expertise, and innovative solutions to drive your business forward. Whether you need assistance with software development, cloud technologies, artificial intelligence, or web technologies, we have the knowledge and experience to help you succeed.
                        </p>
                    </div>
                </div>
            </div>

            {/* row 1 */}
            <div className="row justify-content-center">

                <div className="col-md-5 text-white" id="col1">
                    <div className="container pt-5 pb-5">
                        <h1 className="display-4 fw-bold pt-3 pb-2">Research & Development</h1>
                        <p className="lead">
                        Innovation is at the heart of Akwid Labs. Our R&D team delves into cutting-edge technologies to create groundbreaking solutions. We push the boundaries of what's possible, ensuring your product not only meets but exceeds expectations in terms of performance, security, and scalability.
                        We are here to work on your R&D with experience in MERN, Python, Generative AI, and data engineering.
                        </p>
                    </div>
                </div>
                {/* Column 2 - service 2 */}
                <div className="col-md-5 bg-dark text-white" id="col2">
                    <div className="container pt-5 pb-5">
                        <h1 className="display-4 fw-bold pt-3 pb-2">Artificial Intelligence</h1>
                        <p className="lead">
                            Discover the transformative power of Artificial Intelligence with Akwid Labs. Our AI solutions streamline business processes, enhancing efficiency and reducing costs. Utilizing state-of-the-art technologies and best practices, we develop AI systems that are not only scalable and secure but also seamlessly integrate with your existing infrastructure, driving your business forward.
                        </p>
                    </div>
                </div>
                
            </div>


            {/* row 3 */}
            <div className="text-center p-3 mt-5"
             id="product-pharagraph">
                <div className="lead">
                    If you want one geek or gang of experts to work on your product, we are here to take care of your product. Our team of experts will work with you to understand your vision, goals  and requirements, ensuring that your product not only meets but exceeds expectations.
                </div>
            </div>

        </div>
    );
}

export default ProductEngineering;
