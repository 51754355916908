const Locations = () => {

    return (
        <div className="container-fluid bg-white pt-1 pb-1" id="locations">

            <div className="container text-center p-1">

                <div className="text-center headertext2" id="locations-text"> Meet Us In </div>

                <div className="row justify-content-center mt-1 mb-2 p-2">

                    {/* Sri Lanka */}
                    <div className="col-md-10 p-1" id="locations-card1">
                        <div className="text-center mt-4">
                            <h4>Colombo</h4>
                            <h2>Sri Lanka</h2>
                        </div>
                        <div className="text-center mt-2">
                            <p> 
                                Colombo, the bustling capital of Sri Lanka, is a vibrant city that offers a unique blend of culture, history, and modernity. With its rich heritage and diverse population, Colombo is the perfect place to discuss your next project. Discover the city's hidden gems while exploring innovative solutions and building valuable connections.

                                As a tech team who growed up in Colombo, We are here to help you to build your dream project and take your business to the next level.
                            </p>
                        </div>
                    </div>

                    {/* London
                    <div className="col-md-5 p-3" id="locations-card2">
                        <div className="text-center mt-4">
                            <h4>London</h4>
                            <h2>United Kingdom</h2>
                        </div>
                        <div className="text-center mt-2">
                        <p>London, the historic capital of the UK, is a global hub for business and culture. Surrounded by iconic landmarks like Big Ben and Buckingham Palace, it's an ideal spot to discuss your next big venture. Immerse yourself in London's cosmopolitan vibe while exploring innovative solutions and forging valuable partnerships.</p>
                        </div>
                    </div> */}
                </div>


            </div>
        </div>
    );
}


export default Locations;