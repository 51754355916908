import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Container, DropdownDivider } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
    const logo = require('../Images/Brand-Logo/Akwid-logo.png');

    // get cuurent url path and set active class to the current page
    const currentPath = window.location.pathname;
    console.log(currentPath);

    return (
        <Navbar expand="lg" className="bg-dark navbar-large" variant="dark" sticky="top" id="Navbar"> 
            <Container className="pb-1 pt-1">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Brand href="/home">
                    <img
                        alt="logo of Akwid Labs Pvt Ltd"
                        src={logo}
                        height="45"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
            
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
                    <Nav className="justify-content-start justify-content-lg-center mx-auto text-start">
                        <Nav.Link href="/home" className="m-2" {...(currentPath === "/home" ? {active: true} : {})}>
                            HOME
                        </Nav.Link>
                        <Nav.Link href="/services" className="m-2" {...(currentPath === "/services" ? {active: true} : {})}>
                            SOLUTIONS
                        </Nav.Link>
                        <Nav.Link href="/products" className="m-2" {...(currentPath === "/products" ? {active: true} : {})}>
                            PRODUCTS
                        </Nav.Link>
                        <Nav.Link href="/partnership" className="m-2" {...(currentPath === "/partnership" ? {active: true} : {})}>
                            PARTNERSHIP
                        </Nav.Link>
                        
                        {/* <Nav.Link href="/clients" className="m-2">
                            CLIENTS
                        </Nav.Link> */}
                        <NavDropdown title="MORE" id="basic-nav-dropdown" className="m-2">
                            <NavDropdown.Item href="/casestudies">CASE STUDIES</NavDropdown.Item>
                            <DropdownDivider />
                            <NavDropdown.Item href="/Blog">BLOG</NavDropdown.Item>
                            <DropdownDivider />
                            <NavDropdown.Item href="/careers">CAREERS</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <div className="d-flex justify-content-end">
                        <button 
                            onClick={() => window.open("/contacts", "_self")}
                            className="btn btn-outline pt-2 pb-2 pl-3 pr-3 contact-button m-1"
                        > CONTACT US </button>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;

