const Careersintro = () => {

    const image1 = require('../../Images/Career/design.png');
    const image2 = require('../../Images/Career/develop.png');
    const image3 = require('../../Images/Career/innovation.png');
    return (
        <div className="container careersintro mt-2">
            
            <div className="careersintro-content pt-2 pb-2 pl-5 pr-5">

                <div className="text-center mt-4 headertext1">
                    Be a part of Higly Growing Tech Startup
                </div>

                <p className="text-center textpharagraph mt-1 p-4" id="text2">
                We are actively hiring top-tier Engineering, Data Science, Product Management, Marketing, and Business Development professionals to join our team. If you are passionate about technology and want to be part of a company that is making a difference in the world, we want to hear from you.
                </p>
            </div>

            <div className="careers-benefits mt-5 pt-1 pb-1 pl-5 pr-5 bg-dark text-white" id="careers-benefits">
                <div className="p-2">
                <div className="text-center mt-4 headertext2">
                    No Rules, No Boundaries <br/>Only Solve Problems and Meet Deadlines
                </div>

                <p className="text-center textpharagraph p-4" id="text3">
                    As the part of AI driven product engineering team, you will have the opportunity to work on cutting-edge technologies and solve complex problems. We offer a flexible work environment, competitive salary and remote work options to our employees for better work-life balance. Also, you will get 
                    oppertunity to learn and grow with us. 
                </p>

                <div className="container mt-2 mb-5 justify-content-center">
                    <div className="row justify-content-center">

                            <div className="col-md-3 c1" id="1">
                                <div className="benefits">
                                    <div className="benefits-text">
                                        <img src={image1} alt="Akwid Labs - Work hours" className="img-fluid" />
                                        <h5>Flexible Work Hours</h5>
                                        <p>
                                            We only care about the work you do, not when you do it. We offer flexible work hours. Only deadlines matter.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 c2" id="2">
                                <div className="benefits">
                                    <div className="benefits-text">
                                        <img  src={image2} alt="Akwid Labs  - Remote Work" className="img-fluid" />
                                        <h5>Remote Work</h5>
                                        <p>
                                          Work from anywher you want. We offer remote work options to our employees. So, you can balance your work and life.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 c3" id="3">
                                <div className="benefits">
                                    <div className="benefits-text">
                                        <img src={image3} alt="Akwid Labs - Salary" className="img-fluid" />
                                        <h5>Competitive Salary</h5>
                                        <p>
                                            We offer competitive salary to our employees. We believe in paying our employees what they deserve.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
           



        </div>
    );
    }

export default Careersintro