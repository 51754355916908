import * as Icon from 'react-bootstrap-icons';
import '../../Styles/Partnership.css';

const PartnershipComponent = () => {
    return (
        <>
            <div className="container" id="partnership-component">
                
                <div className="text-center mt-5 mb-5 p-5">
                    <div className="headertext1 text-start">
                        Let's build the future together
                    </div>

                    <div className="text-start textpharagraph mt-3">
                        At Akwid Labs, we are dedicated to developing AI-driven software and SaaS products that empower global businesses with cutting-edge technology. As a forward-thinking startup, we are eager to grow alongside other companies and individuals who share our vision and passion for innovation.

                        <br /><br />

                        We believe in the power of collaboration. By partnering with us, you'll gain access to our expertise in AI and SaaS, helping your business stay ahead in a competitive market. Together, we can achieve remarkable growth and success. Let's join forces to revolutionize the way businesses operate and create a brighter future for all.

                        <br /><br />

                        Join us on this exciting journey and become a part of a community that thrives on innovation, collaboration, and mutual growth. Together, we can reach new heights and achieve our goals.
                    </div>

                </div>


                <div className="text-center mt-3 mb-3 p-5 bg-dark" id='partnership-benefits'>
                    <div className="headertext2 text-center">
                        Benefits of Partnership with Akwid Labs
                    </div>

                    <div className='row mt-2 mb-5 justify-content-center'>

                        {/* Increased Profit Margins */}
                        <div className="col-md-5 card">
                            <div className="text-center mt-3 icons">
                                <Icon.GraphUpArrow className='icon m-3' id='graph' />
                            </div>
                            <div className="text-center">
                                <h5>Increased Profit Margins</h5>
                                <p>
                                    Partner with Akwid Labs to reduce operational costs, increase profit margins, and expand your global customer reach.
                                </p>
                            </div>
                        </div>

                        {/* Cost-Effective Resources */}
                        <div className="col-md-5 card">
                            <div className="text-center mt-3 icons">
                                <Icon.Rocket className='icon m-3' id='rocket' />
                            </div>
                            <div className="text-center">
                                <h5>Cost-Effective Resources</h5>
                                <p>
                                    Benefit from our cost-effective resources and advanced technology solutions to accelerate growth and achieve your business objectives.
                                </p>
                            </div>
                        </div>

                        {/* Technology Challenges */}
                        <div className="col-md-5 card">
                            <div className="text-center mt-3 icons">
                                <Icon.Tools className='icon m-3' id='tools' />
                            </div>
                            <div className="text-center">
                                <h5>Technology Challenges</h5>
                                <p>
                                    Let Akwid Labs handle your toughest technology challenges, allowing you to focus on your core business and drive success.
                                </p>
                            </div>
                        </div>

                        {/* Stay Ahead of the Curve */}
                        <div className="col-md-5 card">
                            <div className="text-center mt-3 icons">
                                <Icon.LightningCharge className='icon m-3' id='lightning' />
                            </div>
                            <div className="text-center">
                                <h5>Stay Ahead of the Curve</h5>
                                <p>
                                    Stay ahead of industry trends with Akwid Labs' cutting-edge product engineering, AI, and SaaS solutions that propel your business forward.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="text-center mt-5 mb-5 p-5">
                    <div className="headertext1 text-start">
                        Why Partner with Akwid Labs?
                    </div>

                    <div className="text-start textpharagraph mt-3">
                        <ul>
                            <li>We will handle the toughest technology challenges so you can focus on your core business.</li>
                            <li>Our team will integrate seamlessly with yours, working together to achieve your goals.</li>
                            <li>You can outsource your IT needs to us and benefit from our cost-effective resources.</li>
                            <li>We can manage your project from start to finish, ensuring a seamless and successful outcome.</li>
                            <li>We will help you stay ahead of the curve with our cutting-edge product engineering, AI, and SaaS solutions.</li>
                            <li>Benefit from our extensive experience and innovative approach to technology.</li>
                            <li>Enjoy peace of mind knowing that your IT infrastructure is in expert hands.</li>
                        </ul>
                    </div>
                </div>

            </div>
        </>
    );
    }

export default PartnershipComponent;