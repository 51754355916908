import ContactsForm from "../Components/Contacts/ContactsForm";
import PageHeaders from "../Components/ExtraComponents/PageHeaders";
import '../Styles/Contact.css';
import ReactGA from 'react-ga4';


const Contacts = () => {

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

    document.title = "Akwid Labs | Contact Us & Get in Touch";

    return (
        <div>
            <PageHeaders title="Contact Us" subtitle="Let's discuss how our team can provide the best solution to solve your problem"/>
            <div className="mt-5 mb-5">
                <ContactsForm /> 
            </div>



            {/* set component to set our phone number and location. Also, invite our discord community */}
            <div className="container-fluid mt-5 pb-3 bg-dark" id="contactform-info">
                <div className="text-center headertext2 pt-5 pb-3">
                    Be a Part of Us
                </div>
                <div className="container p-5">
                        <div className="row justify-content-center">

                            <div className="col-md-4">

                                <h3>
                                    Contact Us 
                                </h3> 


                                <p>Email<br/>
                                     <a href="mailto:hello@akwidlabs.com">hello@akwidlabs.com</a>
                                </p>

                                <p>Phone call or WhatsApp <br/>
                                     <a href="tel:+94769114371">+94 769 114 371</a>
                                </p>

                            </div>

                            <div className="col-md-4">
                                <h3>Location</h3>
                                <p>Colombo, Sri Lanka</p>
                            </div>

                            <div className="col-md-4">
                                <h3>Join Our Discord Fam</h3>
                                <p>Join our discord community to get the latest updates, ask questions and connect with our team.</p>
                                <a href="https://discord.gg/aafQSv7s" target="_blank" rel="noreferrer" className="">Join Discord &rarr;</a>
                            </div>

                        </div>

                        <hr className="bg-light mt-5 mb-5" style={{height: "2px"}}/>
                    </div>
            </div>
        </div>

    );
    }
    
export default Contacts;