import PageHeaders from "../Components/ExtraComponents/PageHeaders";
import UnderDevelopment from "../Components/ExtraComponents/UnderDevelopment";
import ReactGA from 'react-ga4';


const Casestudies = () => {

    document.title = "Akwid Labs | Case Studies";

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });


    return (
        <div>
            <PageHeaders
                title={
                    <>
                        Case Studies <br />
                        
                    </>
                }
                subtitle={<></>}
            />
            <UnderDevelopment />
        </div>
    );
    }

export default Casestudies;