import PageHeaders from "../Components/ExtraComponents/PageHeaders";
import PartnershipComponent from "../Components/ExtraComponents/PartnershipComponent";
import ReactGA from 'react-ga4';

const Partnership = () => {

    document.title = "Akwid Labs | Be a Partners of AI-Driven Tech Company";

    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    
    return(
       <>
            <PageHeaders
                title={
                    <>
                        Be a Partners
                        <br />
                        of 
                        <br />
                        AI-Driven Tech Company

                    </>
                }
                subtitle={<>
                </>}
            />


            {/* content */}
            <PartnershipComponent  />
            


            


       </>
    )
}

export default Partnership;